import React, { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  lineCursor: { cursor: "all-scroll" },
}));

const Line = forwardRef((props, ref) => {
  const classes = useStyles();
  let diffInInitialLeft;
  let diffInInitialTop;
  let diffInFinalLeft;
  let diffInFinalTop;

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleDragStart = (event) => {
    event.stopPropagation();
    props.onSelect(props.id);
    let { clientX, clientY } =
      event.type === "touchstart" ? event.touches[0] : event;
    // diffInInitialLeft is the distance between the draggingX point and startX (Initial X-axis)
    diffInInitialLeft =
      clientX +
      props.imgScrollRef.current.scrollLeft -
      props.imgParentRef.current.offsetLeft -
      props.initialLeftOffset;

    //diffInInitialTop is the distance between the draggingY point and startY (Initial Y-axis)
    diffInInitialTop =
      clientY +
      props.imgScrollRef.current.scrollTop -
      props.imgParentRef.current.offsetTop -
      props.initialTopOffset;

    // diffInFinalLeft is the distance between the dragging-end-X point and endX (Final X-axis).
    diffInFinalLeft =
      props.finalLeftOffset -
      (clientX +
        props.imgScrollRef.current.scrollLeft -
        props.imgParentRef.current.offsetLeft);

    // diffInFinalTop is the distance between the dragging-end-Y point and endY (Final Y-axis).
    diffInFinalTop =
      props.finalTopOffset -
      (clientY +
        props.imgScrollRef.current.scrollTop -
        props.imgParentRef.current.offsetTop);
    props.dragStart(
      props.id,
      diffInInitialLeft,
      diffInInitialTop,
      diffInFinalLeft,
      diffInFinalTop
    );
  };

  useImperativeHandle(ref, () => ({
    calculateUpdatedPositions(
      event,
      elementID,
      diffInStartX,
      diffInStartY,
      diffInEndX,
      diffInEndY
    ) {
      // Else condition will drag the line to updated position
      // Updated Point of startX (Initial-X)
      let { clientX, clientY } =
        event.type === "touchmove"
          ? event.touches[0]
          : event.type === "touchend"
          ? event.changedTouches[0]
          : event;
      const updatedInitialLeft =
        clientX +
        props.imgScrollRef.current.scrollLeft -
        props.imgParentRef.current.offsetLeft -
        diffInStartX;

      // Updated Point of startY (Initial-Y)
      const updatedInitialTop =
        clientY +
        props.imgScrollRef.current.scrollTop -
        props.imgParentRef.current.offsetTop -
        diffInStartY;

      // Updated Point of EndX (Final-X)
      const updatedFinalLeft =
        clientX +
        props.imgScrollRef.current.scrollLeft -
        props.imgParentRef.current.offsetLeft +
        diffInEndX;

      // Updated Point of EndY (Final-Y)
      const updatedFinalTop =
        clientY +
        props.imgScrollRef.current.scrollTop -
        props.imgParentRef.current.offsetTop +
        diffInEndY;
      return {
        elId: elementID,
        updatedInitialLeft,
        updatedInitialTop,
        updatedFinalLeft,
        updatedFinalTop,
      };
    },
  }));

  return (
    <>
      <g>
        <line
          x1={props.initialLeftOffset}
          y1={props.initialTopOffset}
          x2={props.finalLeftOffset}
          y2={props.finalTopOffset}
          stroke="rgb(255,0,0)"
          strokeWidth="3"
          className={classes.lineCursor}
          style={{ cursor: "all-scroll" }}
          onClick={stopPropagation}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
        />
      </g>
    </>
  );
});

export default Line;

import React, { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  lineCursor: { cursor: "all-scroll" },
}));

const FreeHandDrawing = forwardRef((props, ref) => {
  const classes = useStyles();

  const onMouseDown = (event) => {
    event.stopPropagation();
    props.onSelect(props.id);
    event = event.type === "touchstart" ? event.touches[0] : event;
    props.toggleFreeHandShifting(true);
    props.handleFreeHandDragging(props.id, props.drawingPath, event);
  };
  useImperativeHandle(ref, () => ({
    calculateUpdatedPositions(event, freeHandDraggingInitial) {
      let { clientX, clientY } =
        event.type === "touchmove"
          ? event.touches[0]
          : event.type === "touchend"
          ? event.changedTouches[0]
          : event;
      let diffInX = clientX - freeHandDraggingInitial.startX;
      // console.log(`Diff in X = ${diffInX}`);
      let diffInY = clientY - freeHandDraggingInitial.startY;
      // console.log(`Diff in Y = ${diffInY}`);

      const pathString = freeHandDraggingInitial.drawingPath;
      const segments = pathString.split(/(?=[ML])/);

      const coordinates = segments.map((segment) => {
        // Split the segment into W, X and Y parts
        const [w, x, y] = segment.split(/\s+/);
        // Add diffInX to X and diffInY to Y
        const newX = parseFloat(x) + diffInX;
        const newY = parseFloat(y) + diffInY;
        return `${w} ${newX} ${newY}`;
      });

      const updatedcoordinates = coordinates.join(" ");
      return {
        elId: freeHandDraggingInitial.elementID,
        drawingPath: updatedcoordinates,
      };
    },
  }));

  return (
    <>
      <g>
        <path
          d={props.drawingPath}
          stroke="red"
          strokeWidth="3"
          fill="none"
          className={classes.lineCursor}
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
        />
      </g>
    </>
  );
});

export default FreeHandDrawing;

import React from "react";
import classes from "./Modal.module.css";

const Modal = (props, children) => {
  return (
    <div id="imageModal" className={classes.modal}>
      <div className={classes.modalHeader}>
        <p> {props.headerText}</p>
      </div>
      <div className={classes.modalContent}>
        {props.children}
      </div>
      <div className={classes.modalFooter}>    
        <button onClick={props.onProceed}>Proceed</button>
        <button onClick={props.onDiscard}>Discard</button>
      </div>
    </div>
  );
};

export default Modal;
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DeviceContext } from "./DeviceContext";

const useStyles = makeStyles((theme) => ({
  foreignObject: { overflow: "visible", width: "1px", height: "1px" },
  textarea: { cursor: "all-scroll", outline: "none" },
  disablePageScroll: {
    "& textarea": {
      touchAction: "none",
    },
  },
  selected: {
    border: "2px solid #359139",
  },
}));

const CommentTextArea = forwardRef((props, ref) => {
  const deviceContext = useContext(DeviceContext);
  const classes = useStyles();
  const textareaRef = useRef(null);

  let diffInLeft;
  let diffInTop;

  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  const handleDragStart = (event) => {
    event.stopPropagation();
    props.onSelect(props.id);
    let { clientX, clientY } =
      event.type === "touchstart" ? event.touches[0] : event;
    let textareaElement = textareaRef.current;
    // Get textarea coordinates
    var textareaCoordinates = textareaElement.getBoundingClientRect();
    // Calculate the textarea right corner
    let textareaRight =
      textareaCoordinates.right +
      props.imgScrollRef.current.scrollLeft -
      props.imgParentRef.current.offsetLeft;
    // Calculate the textarea bottom corner
    let textareaBottom =
      textareaCoordinates.bottom +
      props.imgScrollRef.current.scrollTop -
      props.imgParentRef.current.offsetTop;

    clientX =
      clientX +
      props.imgScrollRef.current.scrollLeft -
      props.imgParentRef.current.offsetLeft;
    clientY =
      clientY +
      props.imgScrollRef.current.scrollTop -
      props.imgParentRef.current.offsetTop;

    // Check if the mouse is within the resize handle area
    if (clientX >= textareaRight - 16 && clientY >= textareaBottom - 16) {
      // Prevent the event from propagating further
      props.textAreaStatus("Resize");
      event.stopPropagation();
    } else {
      diffInLeft = clientX - props.offsetLeft;
      diffInTop = clientY - props.offsetTop;
      props.dragStart(props.id, diffInLeft, diffInTop);
    }
  };
  useImperativeHandle(ref, () => ({
    calculateUpdatedPositions(event, elementID, diffInLeft, diffInTop) {
      let { clientX, clientY } =
        event.type === "touchmove"
          ? event.touches[0]
          : event.type === "touchend"
          ? event.changedTouches[0]
          : event;
      const updatedLeft =
        clientX +
        props.imgScrollRef.current.scrollLeft -
        props.imgParentRef.current.offsetLeft -
        diffInLeft;

      const updatedTop =
        clientY +
        props.imgScrollRef.current.scrollTop -
        props.imgParentRef.current.offsetTop -
        diffInTop;
      return {
        elId: elementID,
        updatedLeft,
        updatedTop,
      };
    },
  }));
  return (
    <>
      <foreignObject
        x={props.offsetLeft}
        y={props.offsetTop}
        className={`${classes.foreignObject} ${
          deviceContext.isTouchEnabled && classes.disablePageScroll
        }`}
      >
        <textarea
          onClick={stopPropagation}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          className={`${props.selected ? classes.selected : ""} ${
            classes.textarea
          }`}
          ref={textareaRef}
          value={props.value}
          onChange={(event) => props.onTextAreaChange(event, props.id)}
        />
      </foreignObject>
    </>
  );
});

export default CommentTextArea;

import { createContext } from "react";

export const isDeviceTouchEnabled =
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export const DeviceContext = createContext({
  isTouchEnabled: isDeviceTouchEnabled,
});

import React from "react";
import { IoIosRemoveCircleOutline } from "react-icons/io";

const DeleteIcon = ({ el, deleteElement }) => {
  const getIconX = (el) => {
    const X =
      el.type === "Pin"
        ? el.leftOffset - 22
        : el.type === "Textarea"
        ? el.leftOffset - 20
        : el.type === "Line"
        ? el.initialLeftOffset - 13
        : el.type === "FreeHandDrawing"
        ? el.drawingPath.split(" ")[1] - 20
        : "";
    return X;
  };

  const getIconY = (el) => {
    return el.type === "Pin"
      ? el.topOffset - 27
      : el.type === "Textarea"
      ? el.topOffset - 14
      : el.type === "Line"
      ? el.initialTopOffset - 13
      : el.type === "FreeHandDrawing"
      ? el.drawingPath.split(" ")[2] - 20
      : "";
  };

  return (
    <foreignObject x={getIconX(el)} y={getIconY(el)} width="24" height="24">
      <IoIosRemoveCircleOutline
        onClick={(e) => {
          e.stopPropagation();
          deleteElement(el.id);
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
        }}
        style={{
          backgroundColor: "white",
          borderRadius: "3px",
        }}
        aria-label="Delete Icon"
      />
    </foreignObject>
  );
};

export default DeleteIcon;
